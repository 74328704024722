import React, { useContext } from 'react'
import './Intro.css'
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Floating from '../FloatingDiv/Floating';
import Crown from '../../img/crown.png';
import Like from '../../img/thumbup.png'
import { themeContext } from "../../Context";
import { Link } from "react-scroll";

function Intro() {

    // context
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className='intro'>
        <div className="i-left">
            <div className="i-name">
                <span style={{ color: darkMode ? "white" : "" }}>Bonjour! </span> <br />
                <span>je suis kojo</span> <br/>
                <span> Actuellement étudiant à l'UHA 4.0 Ecole du Numérique à Mulhouse, je suis à la recherche d'un stage en tant que développeur informatique du 03 janvier 2023 au 21 juin 2023 ou d'un contrat de professionnalisation pour la prochaine année universitaire.<br/>
                      En préparation à la licence professionnelle développeur informatique, j'ai actuellement participé à 5 projets concret.</span>
            </div>
            <br />
            <Link to="contact" spy={true} smooth={true}>
              <button className='button i-button'> Me contacter</button>
            </Link>
            <div className='i-icons'>
              <a href='https://www.linkedin.com/in/kojo-adjei-353b4215b/'> <img src={LinkedIn} alt="logo linkedin"/></a>
              <a href='https://github.com/kojo68'><img src={Github} alt="logo github"></img></a>
            </div>
        </div>
        <div className="i-right">
          <div className='flot1' >
            <Floating image={Crown} txt1= "Web" txt2="Développeur"/>
          </div>
          <div className='flot2'  style={{marginTop:'2%' , marginLeft: '5%'}}>
            <Floating image={Like} txt1= "Développeur" txt2="Mobile"/>
          </div>
          <div className='blur' style={{background: "rgb(238 210 255)"}}></div>
          <div className='blur' style={{background: '#C1F5FF', top: '1rem', width: '21rem', left: '-2rem'}}></div>
        </div>
    </div>
  )
}

export default Intro