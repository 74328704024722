import React, { useRef , useState, useContext} from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css'
import { themeContext } from "../../Context";

function Contact() {
    const form = useRef();
    const [done, setDone]=useState(false);
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_mgptp9n', 'template_tsblc9l', form.current, 'fGzaNxWeTuUfan9ZS')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <div className='contact' id='contact'>
        <div className='left'>
            <div className='texte'>
                <span style={{color: darkMode?'white': ''}}>N'hésitez pas !</span><br/>
                <span>Contactez moi</span>
            </div>
        </div>

        <div className='right'>
            <form ref={form} onSubmit={sendEmail}>
                <input type="text" name="name" className='user' placeholder='Nom'></input>
                <input type="email" name="mail" className='mail' placeholder='Adresse mail'></input>
                <textarea type="message" name="message" className='message' placeholder='Entrez votre message'></textarea>
                <input type="submit" value="envoyé" className="button"/>
                <span>{done && "Merci de m'avoir contacter"}</span>
                <div className='blur' style={{background: "var(--purple)"}}></div>
            </form>
        </div>
    </div>
  )
}

export default Contact