import React from 'react'
import ebn from '../../img/Screenshot_ebn_App_redim.jpg';
import alter from '../../img/alteralsace-suivi-consommation-app.jpg';
import conste from '../../img/const.png';
import 'swiper/css'
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Portfolio.css"

function Portfolio() {
  return (
    <div className="portfolio" id='portfolio'>
        {/* heading */}
        <span>Projet Récent</span>
        <span>Portfolio</span>
        {/* slider */}
        <div className='conteneur'>
        <Carousel autoPlay infiniteLoop>
          <div>
          <img className='img-carousel' src={ebn} alt=''/>
          <div className='overlay'>
            <h2 className='overlay-title'>Projet EBN</h2>
            <p className='overlay-text'></p>
          </div>
          </div>
          <div>
          <img className='img-carousel' src={alter} alt=''/>
          </div>
          <div>
            <img className='img-carousel' src={conste} alt=''/>
          </div>
        </Carousel>
        </div>
    </div>
  )
}

export default Portfolio