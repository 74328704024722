import React, { useContext }  from 'react'
import './Services.css'
import Emoji from '../../img/heartemoji.png'
import Glasse from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import CV from './CV.pdf'
import { themeContext } from "../../Context";

const Services = () => {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className='services' id='Services'>

        <div className='s-left'>
          <span>Mes Compétences</span>
          <span style={{ color: darkMode ? "white" : "" }}>Voici un aperçu de mes compétences qui sont en constantes évolutions !</span>
          <a href={CV} download>
            <button className='button s-button'>Télécharger mon CV</button>
          </a>
          
        </div>

        <div className='s-right'>
          <div className='Card1'>
            <Card 
            emoji= {Emoji}
            title= {'Devellopement'}
            details= {"html, css, SQL, React, React Native, Ionic, Angular, Python, Java, Mariadb, Mysql   "}/>
          </div>
          <div className='Card2'>
            <Card 
            emoji= {Glasse}
            title= {'Outils'}
            details= {"Git, Jira, Confluence, PlantUML, Linux / Windows, Photoshop, "}/>
          </div>

          <div className='Card3'>
            <Card 
            emoji= {Humble}
            title= {'Capacité'}
            details= {"Développement Front-End, Développement Back-End"}/>
          </div>

          <div className='blur' style={{background: "rgb(238 210 255)"}}></div>
        <div className='blur' style={{background: '#C1F5FF', top: '30rem', width: '21rem', left: '20rem'}}></div>
        </div>
        
    </div>
  )
}

export default Services