import React from 'react'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Wave from '../../img/wave.png'
import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
        <img src={Wave} alt='' style={{width: '100%'}}/>
        <div className="footer-content">
            <span>adjei.kojo68@gmail.com</span>
            <div className="footer-icon">
                <Github color='white' size={'5rem'}/>
            </div>
        </div>
    </div>
  )
}

export default Footer