import React,  { useContext } from 'react'
import './Experience.css'
import { themeContext } from "../../Context";

function Experience() {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className='experience'id='experience'>
    
    <div className='achivement'>
        <div className='cercle' style={{color: darkMode?'var(--orange)':''}}>2+</div>
        <span>années d'expériences</span>
    </div>

    <div className='achivement'>
        <div className='cercle'style={{color: darkMode?'var(--orange)':''}}>5+</div>
        <span>projet </span>
    </div>

    <div className='achivement'>
        <div className='cercle' style={{color: darkMode?'var(--orange)':''}}>5+</div>
        <span>Entreprise</span>
    </div>

    </div>
  )
}

export default Experience