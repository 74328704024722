import React from 'react'
import './Card.css'

function Card({emoji , title , details} ) {
  return (
    <div className='card'>
        <img src={emoji} alt= 'emoji'/>
        <span>{title}</span>
        <span className='details'>{details}</span>
    </div>
  )
}

export default Card