import React from 'react'
import Toggle from '../Togle/Toggle'
import './Navbar.css'
import { Link } from "react-scroll";

function Navbar() {
  return (
<div className="n-Wrapper">
    <div className="n-left">
        <div className='n-name'>Adjei kojo</div>
        <Toggle/>
    </div>
    
    <div className="n-right">
        <div className="n-list">
            <ul style={{listStyleType: 'none'}}>

            <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                <li>home</li>
            </Link>

            <Link to="services" spy={true} smooth={true}>
                Serivces
            </Link>

            <Link to="experience" spy={true} smooth={true}>
                <li>Expérience</li>
            </Link>

            <Link to="portfolio" spy={true} smooth={true}>
                Portfolio
            </Link>
            </ul>
        </div>
        <Link to="contact" spy={true} smooth={true}>
            <button className='button n-button'> Me contacter</button>
        </Link>
    </div>
</div>
  )
}

export default Navbar